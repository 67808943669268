import { domAnimation, LazyMotion, m } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import useChat from "../../../hooks/useChat";
import ChatboxLoader from "../../elements/loaders/ChatboxLoader";
import newChatSc from "./botScenarios/newChatSc";
import ChatMessageDateGroup from "./ChatMessageDateGroup";

const welcomeMessageTranslations = {
  tr: [
    { message: "Merhaba", delay: 500 },
    { message: "Destek ekibinden arkadaşımız bağlanmak üzere.", delay: 1500 },
    {
      message:
        'Görüşmemiz boyunca paylaşacağın kişisel verilerin KVKK kapsamında korunacağını ve görüşmeye devam ederek <a href="/kvkk-kapsaminda-aydinlatma-metni">sözleşmeyi</a> kabul ettiğini varsaydığımızı belirtelim.',
      delay: 2000,
    },
  ],
  en: [
    { message: "Welcome!", delay: 500 },
    { message: "Our support team is about to connect you.", delay: 1500 },
    {
      message:
        'Let us state the personal data you will share during our meeting will be protected within the scope of GDPR and that you accept the <a href="/en/kvkk-kapsaminda-aydinlatma-metni">privacy agreement</a> by continuing the conversation.',
      delay: 2000,
    },
  ],
  ru: [
    { message: "Добро пожаловать!", delay: 500 },
    {
      message: "Наша служба поддержки свяжется с вами в течение минуты.",
      delay: 1500,
    },
    {
      message:
        'Позвольте заявить, что мы предполагаем, что личные данные, которыми вы поделитесь во время нашей встречи, будут защищены в рамках GDPR, и что вы принимаете <a href="/ru/kvkk-kapsaminda-aydinlatma-metni">соглашение о конфиденциальности</a> продолжая разговор.',
      delay: 2000,
    },
  ],
  es: [
    { message: "Bienvenido!", delay: 500 },
    {
      message: "Nuestro equipo de soporte está a punto de conectarte.",
      delay: 1500,
    },
    {
      message:
        'Permítanos indicar que los datos personales que compartirá durante nuestra reunión estarán protegidos dentro del alcance de GDPR y que acepta el < a href = "/es/kvkk-kapsaminda-aydinlatma-metni" > acuerdo de privacidad</ > al continuar la conversación.',
      delay: 2000,
    },
  ],
};

const notWorkingHoursMessageTranslations = {
  tr: [
    { message: "Merhaba!", delay: 500 },
    {
      message: "Şu anda mesai saatleri dışındayız.",
      delay: 1000,
    },
    {
      message:
        "Lütfen bize sorunuzu ve size geri dönebilmemiz için telefon numaranızı ya da e-posta adresininizi bırakın.",
      delay: 1500,
    },
    {
      message:
        "Mesai saatleri içerisinde size dönüş sağlanacaktır. İlginiz için teşekkür ederiz.",
      delay: 1000,
    },
    {
      message:
        "Paylaşacağın kişisel verilerin KVKK kapsamında korunacağını ve görüşmeye devam ederek sözleşmeyi kabul ettiğini varsaydığımızı belirtelim.",
      delay: 2000,
    },
  ],
  en: [
    { message: "Hello!", delay: 500 },
    { message: "We are currently out of office hours.", delay: 1000 },
    {
      message: "Please leave your phone number or email address.",
      delay: 1500,
    },
    { message: "We will get back to you as soon as possible.", delay: 1000 },
    {
      message:
        "We state that your personal data will be protected within the scope of GDPR and that you accept the privacy agreement by continuing the conversation.",
      delay: 2000,
    },
  ],
  ru: [
    { message: "Привет!", delay: 500 },
    { message: "Мы сейчас вне рабочих часов.", delay: 1000 },
    {
      message:
        "Пожалуйста, оставьте свой номер телефона или адрес электронной почты.",
      delay: 1500,
    },
    { message: "Мы свяжемся с вами в ближайшее время.", delay: 1000 },
    {
      message:
        "Позвольте заявить, что мы предполагаем, что личные данные, которыми вы поделитесь во время нашей встречи, будут защищены в рамках GDPR, и что вы принимаете соглашение о конфиденциальности продолжая разговор.",
      delay: 2000,
    },
  ],
  es: [
    { message: "¡Hola!", delay: 500 },
    {
      message: "Actualmente estamos fuera de horario de oficina.",
      delay: 1000,
    },
    {
      message:
        "Por favor, deje su número de teléfono o dirección de correo electrónico.",
      delay: 1500,
    },
    {
      message: "Nos pondremos en contacto con usted lo antes posible.",
      delay: 1000,
    },
    {
      message:
        "Permítanos indicar que los datos personales que compartirá durante nuestra reunión estarán protegidos dentro del alcance de GDPR y que acepta el acuerdo de privacidad al continuar la conversación.",
      delay: 2000,
    },
  ],
};

const ChatMessageList = ({
  user,
  supabaseUser,
  supabaseToken,
  chatId,
  locale,
  state,
  setState,
  newChat,
}) => {
  const inboxRef = useRef();
  const [refresh, setRefresh] = useState(false);
  const { messages, loading, chat, mutateIntialChat, channelError } = useChat({
    chatId,
    inboxRef,
    supabaseUser,
    supabaseToken,
    refresh,
  });

  useEffect(() => {
    if (newChat) {
      // If it's in working hours and weekdays.
      if (
        new Date().getHours() >= 10 &&
        new Date().getHours() <= 20 &&
        new Date().getDay() !== 0 &&
        new Date().getDay() !== 6
      ) {
        newChatSc({
          chatId,
          typingHandler: setState,
          actionList: welcomeMessageTranslations[locale],
        });
      }
      // Ask for message and contact info
      else {
        newChatSc({
          chatId,
          typingHandler: setState,
          actionList: notWorkingHoursMessageTranslations[locale],
        });
      }
    }
  }, [newChat, chatId, setState, state.agentName, locale]);

  useEffect(() => {
    if (!loading) {
      inboxRef.current?.scrollIntoView({ behavior: "smooth", threshold: 0 });
    }
  });

  useEffect(() => {
    setRefresh(true);
    mutateIntialChat();
    return () => {
      setRefresh(false);
    };
  }, [mutateIntialChat]);

  return (
    <div className="flex flex-col flex-1 gap-4">
      {!loading && !channelError ? (
        Object.keys(messages).map((key) => {
          return (
            <ChatMessageDateGroup
              key={key}
              supabaseUser={supabaseUser}
              locale={locale}
              user={user}
              date={key}
              messages={messages[key]}
              translation={state.translation}
            />
          );
        })
      ) : (
        <div className="flex items-center justify-center flex-1 h-full my-auto">
          <ChatboxLoader />
        </div>
      )}
      {(chat.agent_typing || state.typingEffect) && (
        <div
          className={`text-sm shadow-sm rounded-md py-3 px-4 max-w-max bg-white mb-4`}
        >
          <LazyMotion features={domAnimation}>
            <m.div
              animate={{ opacity: 1 }}
              transition={{
                repeat: Infinity,
                repeatType: "reverse",
                duration: 1,
                ease: "linear",
              }}
              className="flex items-center justify-center gap-2"
            >
              <m.span
                animate={{ y: 2 }}
                initial={{ y: -2 }}
                transition={{
                  repeat: Infinity,
                  repeatType: "reverse",
                  duration: 1,
                }}
                className="bg-neutral-600 h-[8px] w-[8px] rounded-full"
              ></m.span>
              <m.span
                animate={{ y: 2 }}
                initial={{ y: -2 }}
                transition={{
                  repeat: Infinity,
                  repeatType: "reverse",
                  duration: 1,
                  delay: 0.3,
                }}
                className="bg-neutral-700 h-[8px] w-[8px] rounded-full"
              ></m.span>
              <m.span
                animate={{ y: 2 }}
                initial={{ y: -2 }}
                transition={{
                  repeat: Infinity,
                  repeatType: "reverse",
                  duration: 1,
                }}
                className="bg-neutral-600 h-[8px] w-[8px] rounded-full"
              ></m.span>
            </m.div>
          </LazyMotion>
        </div>
      )}
      <div className="mt-20 sm:mt-5" ref={inboxRef}></div>
    </div>
  );
};

export default ChatMessageList;
