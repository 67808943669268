import { Check, DoubleCheck } from "akar-icons";
import parse from "html-react-parser";
import Image from "next/dist/client/image";
import dynamic from "next/dynamic";
import { useEffect, useMemo, useState } from "react";
import useInView from "react-cool-inview";
import { isMobile } from "react-device-detect";
import getLocalTimeChat from "../../../libs/getLocalTimeChat";
import messageSound from "../../../public/message.mp3";
import supabase from "../../../supabase/client";
import FilePreview from "./FilePreview";
import LinkPreview from "./LinkPreview";
const ImageWithPreview = dynamic(() => import("./ImageWithPreview"));

const ChatMessage = ({
  avatar,
  id,
  message,
  time,
  locale,
  userMessage,
  seen,
  initial,
  chat_id,
  type,
  translation,
  translated_message,
}) => {
  const { observe } = useInView({
    threshold: 1,
    onEnter: async ({ unobserve }) => {
      unobserve();
      try {
        if (!userMessage && !seen && type != "bot") {
          await supabase.from("messages").update({ seen: true }).eq("id", id);

          // Decrement new message count

          await supabase.rpc("decrement_new_message_user", {
            row_id: chat_id,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  const [isNotified, setIsNotified] = useState(false);
  const notificationSound = useMemo(() => {
    return new Audio(messageSound);
  }, []);
  useEffect(() => {
    if (!userMessage && !initial && !isNotified && !isMobile) {
      notificationSound.play();
      setIsNotified(true);
    }
  }, [userMessage, initial, isNotified, notificationSound]);

  const options = {
    replace: (domNode) => {
      if (!domNode.attribs) return;
      if (domNode.attribs && domNode.name === "a") {
        return (
          <LinkPreview
            url={domNode.attribs.href}
            text={domNode.children?.[0]?.data}
          />
        );
      } else if (domNode.attribs && domNode.name === "img") {
        return (
          <div className="relative object-cover max-w-3xl blog-post-inner-image cursor-zoom-in">
            <ImageWithPreview
              source={domNode.attribs.src}
              alt={domNode.attribs.alt}
            />
          </div>
        );
      } else if (domNode.attribs && domNode.attribs.type === "file") {
        return (
          <FilePreview
            name={domNode.attribs.name}
            id={domNode.attribs.id}
            size={domNode.attribs.size}
            extension={domNode.attribs.extension}
          />
        );
      }
    },
  };

  return (
    <li className="flex items-start gap-4">
      {!userMessage && (
        <div className="shrink-0">
          <div className="w-8 h-8 overflow-hidden rounded-full">
            <Image
              src={process.env.NEXT_PUBLIC_IMAGE_URL + avatar + "?key=avatar"}
              alt="avatar"
              width={30}
              height={30}
              layout="responsive"
              objectFit="cover"
              objectPosition="center"
            />
          </div>
        </div>
      )}
      <div className="w-full">
        <div
          className={`flex flex-col ${
            userMessage ? "justify-end pl-10" : "justify-start pr-10"
          }`}
        >
          <div
            ref={observe}
            className={`text-sm shadow-sm rounded-md py-2 px-3 max-w-max ${
              userMessage
                ? "bg-emerald-800 text-white place-self-end ml-auto"
                : "bg-white"
            }`}
          >
            {parse(
              type === "message" && translation && !userMessage
                ? translated_message || message
                : message,
              options
            )}
          </div>
          <div
            className={`text-xs flex items-center gap-2 text-neutral-400 mt-2 ${
              userMessage ? "ml-auto" : ""
            }`}
          >
            <span>{getLocalTimeChat(locale, time)}</span>
            {userMessage && (
              <div className={`${seen ? "text-emerald-800" : ""}`}>
                {seen ? <DoubleCheck size={14} /> : <Check size={14} />}
              </div>
            )}
          </div>
        </div>
      </div>
    </li>
  );
};

export default ChatMessage;
