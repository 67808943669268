import { useAtom } from "jotai";
import { useRouter } from "next/dist/client/router";
import { useCallback, useEffect, useState } from "react";
import useSWR from "swr";
import {
  channelAtom,
  channelErrorAtom,
  socketAtom,
} from "../components/elements/ui/ChatBoxPopup";
import chatboxContext from "../context/chatboxContext";
import getLocalTimeChat from "../libs/getLocalTimeChat";
import supabase from "../supabase/client";

const useChat = ({ chatId, inboxRef, supabaseToken, refresh }) => {
  const [messages, setMessages] = useState([]);
  const [chat, setChat] = useState({ user_typing: false, agent_typing: false });
  const [loading, setLoading] = useState(true);
  const [channel] = useAtom(channelAtom);
  const [chatChannel, setChatChannel] = useState(null);
  const [channelError] = useAtom(channelErrorAtom);
  const [socketClient] = useAtom(socketAtom);
  const { locale } = useRouter();
  const [, setChatbox] = useAtom(chatboxContext);

  // Group by Function
  const groupBy = (array, key) => {
    // Return the reduced array
    return array.reduce((result, currentItem) => {
      // If an array already present for key, push it to the array. Otherwise create an array and push the object.
      (result[currentItem[key]] = result[currentItem[key]] || []).push(
        currentItem
      );
      // return the current iteration `result` value, this will be the next iteration's `result` value and accumulate
      return result;
    }, {}); // Empty object is the initial value for result object
  };

  // Message Subscription Event Handler
  const messageHandler = useCallback(
    (payload) => {
      const data = payload.record;
      // INSERT MESSAGE
      if (payload.type === "INSERT") {
        const date = getLocalTimeChat(locale, data.created_at, true);
        setMessages((prev) => {
          let lastKey = Object.keys(prev).slice(-1)[0];
          // Create shallow copy of array
          if (lastKey) {
            let todaysArray = [...prev[lastKey]];
            todaysArray.push({
              id: data.id,
              message: data.message,
              created_at: data.created_at,
              date,
              user_id: data.user_id,
              avatar: data.avatar,
              seen: data.seen,
              chat_id: data.chat_id,
              type: data.type,
              translated_message: data.translated_message,
            });
            return { ...prev, [lastKey]: todaysArray };
          } else {
            return {
              ...prev,
              [date]: [
                {
                  id: data.id,
                  message: data.message,
                  created_at: data.created_at,
                  date,
                  user_id: data.user_id,
                  avatar: data.avatar,
                  seen: data.seen,
                  chat_id: data.chat_id,
                  type: data.type,
                  translated_message: data.translated_message,
                },
              ],
            };
          }
        });
      }
      // UPDATE MESSAGE
      else if (payload.type === "UPDATE") {
        const dateISO = new Date(data.created_at).toISOString();
        const date = getLocalTimeChat(locale, dateISO, true);
        setMessages((prev) => {
          const key = Object.keys(prev).find((key) => key === date);
          const updatedArray = prev[key].map((message) => {
            if (message.id === payload.old_record.id) {
              return {
                ...message,
                seen: data.seen,
              };
            } else {
              return message;
            }
          });
          return { ...prev, [key]: updatedArray };
        });
      } else if (payload.type === "DELETE") {
        const date = getLocalTimeChat(payload.old_record.created_at, true);
        setMessages((prev) => {
          const key = Object.keys(prev).find((key) => key === date);
          const updatedArray = prev[key].filter(
            (message) => message.id !== payload.old_record.id
          );
          return { ...prev, [key]: updatedArray };
        });
      }
    },
    [locale]
  );

  // Scrool bottom
  const scrollToBottom = useCallback(() => {
    inboxRef?.current?.scrollIntoView({ behavior: "smooth", threshold: 0 });
  }, [inboxRef]);

  // Connect Channels
  useEffect(() => {
    // Chat Channel
    const chatChannel = socketClient?.channel(
      `realtime:public:chats:id=eq.${chatId}`,
      { user_token: supabaseToken }
    );
    chatChannel
      ?.subscribe()
      .receive("ok", () => {
        console.log("Subscribed to chat channel");
        setChatChannel(chatChannel);
      })
      .receive("error", () => {
        console.log("Chat Subscription Failed");
      })
      .receive("timeout", () => {
        console.log("Network Problem");
      });

    return () => {
      chatChannel?.unsubscribe();
    };
  }, [chatId, supabaseToken, socketClient, setChatChannel]);

  // Handle Socket Events (Messages)
  useEffect(() => {
    if (channel) {
      channel.on("*", (payload) => {
        messageHandler(payload);
        scrollToBottom();
      });
    }
  }, [channel, messageHandler, scrollToBottom]);

  // Handle Socket Events (Chat)
  useEffect(() => {
    if (chatChannel) {
      chatChannel.on("*", (payload) => {
        if (payload.type === "UPDATE") {
          setChat((chat) => ({
            ...chat,
            user_typing: payload.record.user_typing,
            agent_typing: payload.record.agent_typing,
          }));
          // Chatbox State
          setChatbox((prev) => {
            return {
              ...prev,
              agentName: payload.record.responsible_agent_name,
              translation: payload.record.translation,
            };
          });
        }
      });
    }
  }, [chatChannel, setChatbox]);

  // Initial Chat Fetcher
  const getMessages = async (chat_id) => {
    let date = getLocalTimeChat(locale, new Date().toISOString(), true);
    let { data: messages, error } = await supabase
      .from("messages")
      .select("*")
      .eq("chat_id", chat_id)
      .order("id", { ascending: true });

    if (!error && messages.length > 0) {
      const dateCorrectedMessages = messages.map((message) => {
        return {
          ...message,
          date: getLocalTimeChat(locale, message.created_at, true),
          initial: true,
        };
      });

      const groupedMessages = groupBy(dateCorrectedMessages, "date");
      if (Object.keys(groupedMessages).slice(-1)[0] === date) {
        return groupedMessages;
      } else {
        return { ...groupedMessages, [date]: [] };
      }
    } else if (!error && messages.length === 0) {
      return { [date]: [] };
    }
  };

  // SWR Chat
  const {
    data: initialMessages,
    error: initialMessagesError,
    mutate: mutateIntialChat,
  } = useSWR(chatId, getMessages);

  // Set Initial Messages
  useEffect(() => {
    if (initialMessages && (loading || refresh) && !initialMessagesError) {
      setMessages(initialMessages);
      setLoading(false);
    }
  }, [initialMessages, loading, initialMessagesError, refresh]);

  return {
    messages,
    loading,
    chat,
    channelError,
    mutateIntialChat,
  };
};

export default useChat;
