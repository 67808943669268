import { ChatBubble, ChevronDown } from "akar-icons";
import dynamic from "next/dynamic";
const MiniMessageBox = dynamic(() => import("./MiniMessageBox"));

import { LazyMotion, domAnimation, m } from "framer-motion";
import { useState } from "react";
import { useRouter } from "next/router";

const ChatboxButton = ({ online = false, show, setShow }) => {
  const router = useRouter();
  const [hidden] = useState(
    router.route.includes("/tarim-sirketleri") ||
      router.route.includes("/is-ilanlari") ||
      router.route.includes("/fuarlar") ||
      router.route.includes("/kayit/tanisma") ||
      router.route.includes("/duzenle")
  );

  return (
    <div
      className={`flex flex-col items-end justify-end ${
        hidden ? "hidden sm:flex" : ""
      }`}
    >
      {online && (
        <div className="mb-4">
          <MiniMessageBox />
        </div>
      )}

      <button
        onClick={() => {
          setShow();
        }}
        className="relative z-40 p-3 rounded-full outline-none ring-2 ring-primaryLight bg-neutral-900 text-primaryLight"
      >
        {!show.show ? (
          <LazyMotion key="1" features={domAnimation}>
            <m.div animate={{ scale: 1 }} initial={{ scale: 0.5 }}>
              <ChatBubble size={24} />
            </m.div>
          </LazyMotion>
        ) : (
          <LazyMotion key="2" features={domAnimation}>
            <m.div animate={{ rotateZ: "0deg" }} initial={{ rotateZ: "30deg" }}>
              <ChevronDown size={24} />
            </m.div>
          </LazyMotion>
        )}
      </button>
    </div>
  );
};

export default ChatboxButton;
