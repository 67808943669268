import { atom } from "jotai";
const chatboxContext = atom({
  authenticated: false, // is the user authenticated?
  loading: true, // loading state
  page: "home", // home, chat, etc.
  anonymous: false, // if user is anonymous
  chatId: "", // chat id
  agentName: "", // name of the agent
  newChat: false, // if true, the bots send GDPR messages
  typingEffect: false, // for bots typing effect
  translation_target: "tr",
});

export default chatboxContext;
