import { useRouter } from "next/router";
import { useEffect } from "react";
import ChatboxLoader from "../../elements/loaders/ChatboxLoader";
import ChatboxContainer from "./ChatboxContainer";
import ChatboxHeader from "./ChatboxHeader";

const Chatbox = ({
  closeHandler,
  setChatboxStatus,
  chatboxStatus,
  user,
  supabaseUser,
  supabaseToken,
}) => {
  const { locale } = useRouter();

  // Check user auth status
  useEffect(() => {
    if (supabaseUser) {
      setChatboxStatus((state) => {
        return {
          ...state,
          authenticated: true,
          loading: false,
        };
      });
    } else {
      setChatboxStatus((state) => {
        return {
          ...state,
          authenticated: false,
          loading: false,
        };
      });
    }
  }, [user, supabaseUser, setChatboxStatus]);

  return (
    <div className="bg-white overscroll-none flex flex-col rounded-md w-screen h-screen sm:w-96 sm:h-auto sm:min-h-[600px] sm:max-h-[600px] shadow-md sm:mb-10 z-[100]">
      <ChatboxHeader
        state={chatboxStatus}
        setState={setChatboxStatus}
        locale={locale}
        closeHandler={closeHandler}
        supabaseUser={supabaseUser}
      />
      {chatboxStatus.loading ? (
        <ChatboxLoader />
      ) : (
        <ChatboxContainer
          state={chatboxStatus}
          setState={setChatboxStatus}
          locale={locale}
          user={user}
          supabaseUser={supabaseUser}
          supabaseToken={supabaseToken}
        />
      )}
    </div>
  );
};

export default Chatbox;
