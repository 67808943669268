import dynamic from "next/dynamic";
import ChatboxHome from "./screens/ChatboxHome";
import ChatPage from "./screens/ChatPage";
const UnauthenticatedPage = dynamic(() =>
  import("./screens/UnauthenticatedPage")
);
const ChatboxContainer = ({
  locale,
  state,
  setState,
  user,
  supabaseUser,
  supabaseToken,
}) => {
  if (state.authenticated) {
    if (state.page === "chat") {
      return (
        <ChatPage
          chatId={state.chatId}
          newChat={state.newChat}
          setState={setState}
          state={state}
          locale={locale}
          user={user}
          supabaseUser={supabaseUser}
          supabaseToken={supabaseToken}
        />
      );
    } else if (state.page === "home") {
      return (
        <ChatboxHome
          setState={setState}
          locale={locale}
          user={user}
          supabaseUser={supabaseUser}
        />
      );
    } else if (state.page === "error") {
      return <div>Error</div>;
    }
  } else if (!state.authenticated) {
    return (
      <>
        <UnauthenticatedPage locale={locale} />
      </>
    );
  }
};

export default ChatboxContainer;
